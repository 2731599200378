<script>
  import { mdiOpenInNew } from "@mdi/js";
  import { defineComponent } from "@vue/composition-api";

  export default defineComponent({
    props: {
      projectId: {
        type: String,
        required: true
      },
      constId: {
        type: String,
        required: true
      }
    },
    setup() {
      return {
        icons: {
          mdiOpenInNew
        }
      };
    }
  });
</script>

<template>
  <div class="d-flex justify-end">
    <router-link
      :to="{
        name: 'apps-estimate-items',
        params: {
          project: projectId,
          record: constId
        }
      }"
      target="_blank"
    >
      <v-btn small plain>
        <span class="secondary--text font-weight-semibold">
          Short List Item Reference
        </span>
        <v-icon right small color="secondary">
          {{ icons.mdiOpenInNew }}
        </v-icon>
      </v-btn>
    </router-link>
  </div>
</template>
