<script>
  import { defineComponent } from "@vue/composition-api";

  import themeConfig from "@/themeConfig";
  export default defineComponent({
    props: {
      proceedNum: {
        type: String,
        required: true
      },
      permitNum: {
        type: String,
        required: true
      }
    },
    setup() {
      return {
        reportAgency: themeConfig.reporting.tsAgency,
        reportDept: themeConfig.reporting.tsDept,
        reportTitle: themeConfig.reporting.tsTitle,
        appLogo: themeConfig.app.logoDark
      };
    }
  });
</script>

<template>
  <v-card-text class="py-9 px-8">
    <div class="invoice-header d-flex flex-wrap justify-space-between">
      <!-- Left Content -->
      <div class="mb-6">
        <div class="d-flex align-center mb-6">
          <v-img
            :src="appLogo"
            max-height="30px"
            max-width="30px"
            alt="logo"
            contain
            class="me-3"
          ></v-img>
          <span class="text--primary font-weight-semibold text-xl">
            {{ reportAgency }}
          </span>
        </div>
        <span class="d-block font-weight-semibold text-lg">{{
          reportDept
        }}</span>
        <span class="d-block font-weight-semibold text-lg">
          {{ reportTitle }}
        </span>
      </div>
      <!-- Right Content -->
      <div :class="$vuetify.rtl ? 'text-left' : 'text-right'">
        <div class="mb-4 d-flex align-center justify-end">
          <span class="me-2 font-weight-regular text-lg text--primary">
            Permit:
          </span>
          <v-text-field
            v-model="permitNum"
            outlined
            dense
            disabled
            class="header-inputs flex-grow-0"
            hide-details="auto"
          ></v-text-field>
        </div>
        <div class="mb-4 d-flex align-center">
          <span class="me-2 font-weight-regular text-lg text--primary">
            Project:
          </span>
          <v-text-field
            v-model="proceedNum"
            outlined
            dense
            disabled
            class="header-inputs flex-grow-0"
            hide-details="auto"
          ></v-text-field>
        </div>
      </div>
    </div>
  </v-card-text>
</template>
