<script>
  import { defineComponent } from "@vue/composition-api";

  import { snackbar } from "@/components/base/snackbar/useSnackbar";
  import { store } from "@/store";

  export default defineComponent({
    props: {
      isLoadEstimateItemsDialogOpen: {
        type: Boolean,
        required: true
      },
      projectId: {
        type: String,
        required: true
      },
      constId: {
        type: String,
        required: true
      },
      shortListId: {
        type: String,
        required: true
      }
    },
    setup(props, { emit }) {
      const loading = store.state["estimate-items"].loading;

      const onCancel = () => {
        // close dialog and redirect to project page on cancel
        emit("close-dialog-redirect", {
          isLoadEstimateItemsDialogOpen: false,
          project: props.projectId
        });
      };

      const handleFormSubmit = async (projectId, constId, shortListId) => {
        try {
          store.dispatch("estimate-items/updateLoading", true);
          emit("create-estimate-item-list", {
            projectId: projectId,
            constId: constId,
            shortListId: shortListId
          });
        } catch (error) {
          snackbar({
            text: `Unable to generate items list. Please try again later. If the problem contiues, please contact your Administrator.`,
            color: "error"
          });
        } finally {
          emit("close-dialog", false);
        }
      };

      return {
        loading,
        onCancel,
        handleFormSubmit
      };
    }
  });
</script>
<template>
  <v-fade-transition>
    <v-card light max-width="550">
      <v-card-title> Generate Item Selection List </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div>
          <p>
            The list of items eligible for selection has not been generated for
            this construction record. Generate the list now?
          </p>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn type="reset" color="secondary" text @click="onCancel">
          Cancel
        </v-btn>
        <v-btn
          type="submit"
          color="primary"
          @click="handleFormSubmit(projectId, constId, shortListId)"
        >
          Generate List
        </v-btn>
      </v-card-actions>
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-card>
  </v-fade-transition>
</template>
