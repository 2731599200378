<script>
  import { mdiCheckDecagramOutline, mdiContentSaveOutline } from "@mdi/js";
  import { computed, defineComponent, ref, watch } from "@vue/composition-api";

  // Notification
  import { snackbar } from "@/components/base/snackbar/useSnackbar";
  import { useEstimateItemsByConstructionRecordId } from "@/composables/useEstimateItems";
  import { useRouter } from "@/helpers";
  import { store } from "@/store";
  // Components
  import ConstructionRecordHeader from "@/views/apps/construction-record/construction-record-edit/ConstructionRecordHeader.vue";
  import ConstructionRecordShortlistViewer from "@/views/apps/construction-record/construction-record-edit/ConstructionRecordShortlistViewer.vue";
  import LoadRecordEstimateItems from "@/views/apps/construction-record/construction-record-edit/LoadRecordEstimateItems.vue";
  import {
    useConstructionRecord,
    useLoadRecordEstimateItemsDialog
  } from "@/views/apps/construction-record/useConstructionRecord.js";
  import { useUpdateEstimateItemsList } from "@/views/apps/construction-record/useConstructionRecord.js";
  import { useProject } from "@/views/apps/project/useProject.js";

  import ConstructionRecordEditable from "../ConstructionRecordEditable.vue";
  import ConstructionRecordSelectedItems from "./ConstructionRecordSelectedItems.vue";

  export default defineComponent({
    components: {
      ConstructionRecordHeader,
      ConstructionRecordShortlistViewer,
      ConstructionRecordEditable,
      ConstructionRecordSelectedItems,
      LoadRecordEstimateItems
    },
    setup() {
      const { route } = useRouter();
      const { project, projectHasLoaded } = useProject();
      const datatableKey = ref(0);
      const { constructionRecord } = useConstructionRecord(
        route.value.params.record
      );

      const { estimateItems, estimateItemsHaveLoaded } =
        useEstimateItemsByConstructionRecordId();

      const projectId = route.value.params.project;
      const constId = route.value.params.record;
      const shortListId = computed(() => {
        return project.value.short_list_record;
      });

      const {
        isLoadEstimateItemsDialogOpen,
        processFormData,
        closeLoadRecordEstimateItemsDialog,
        closeLoadRecordEstimateItemsDialogAndRedirect
      } = useLoadRecordEstimateItemsDialog();

      const { processSelectedItems, updateConstructionRecordStatus } =
        useUpdateEstimateItemsList();

      let selectedEstimateItem = ref({});
      const isEditSession = ref(false);

      const handleFormSubmit = async () => {
        try {
          store.dispatch("estimate-items/updateLoading", true);
          processSelectedItems();
        } catch (error) {
          snackbar({
            text: `Unable to submit form. Please contact your Administrator.`,
            color: "error"
          });
        } finally {
          setTimeout(() => {
            store.dispatch("estimate-items/updateLoading", false);
            snackbar({
              text: `Traffic Statement Items have been updated!`,
              color: "success",
              timeout: 3000
            });
            forceRerender();
          }, 3000);
        }
      };
      const forceRerender = () => {
        //console.log("Inside forceRerender", datatableKey.value);
        datatableKey.value += 1;
      };

      const handleMarkAsComplete = async () => {
        try {
          store.dispatch("estimate-items/updateLoading", true);
        } catch (error) {
          snackbar({
            text: `Unable to mark as complete. Please contact your Administrator.`,
            color: "error"
          });
        } finally {
          setTimeout(() => {
            store.dispatch("estimate-items/updateLoading", false);
            updateConstructionRecordStatus(constId);
            snackbar({
              text: `Traffic Statement has been marked as complete!`,
              color: "success",
              timeout: 3000
            });
            forceRerender();
          }, 3000);
        }
      };

      watch(estimateItems, (value) => {
        if (value.length > 0 || value === "undefined") {
          isLoadEstimateItemsDialogOpen.value = false;
        }
      });

      return {
        datatableKey,
        project,
        projectHasLoaded,
        constructionRecord,
        projectId,
        constId,
        shortListId,
        estimateItems,
        estimateItemsHaveLoaded,
        isLoadEstimateItemsDialogOpen,
        closeLoadRecordEstimateItemsDialog,
        closeLoadRecordEstimateItemsDialogAndRedirect,
        selectedEstimateItem,
        isEditSession,
        icons: {
          mdiCheckDecagramOutline,
          mdiContentSaveOutline
        },
        handleFormSubmit,
        processFormData,
        processSelectedItems,
        handleMarkAsComplete,
        updateConstructionRecordStatus
      };
    }
  });
</script>

<template>
  <v-row
    v-if="projectHasLoaded && estimateItemsHaveLoaded && constructionRecord"
    class="app-invoice-preview"
  >
    <v-col cols="12" md="9">
      <v-card class="app-invoice-editable">
        <!-- Header -->
        <construction-record-header
          :proceed-num="project.proceed_num"
          :permit-num="constructionRecord.permit_num"
        />
        <v-divider></v-divider>
        <construction-record-shortlist-viewer
          :project-id="project.fulcrum_id"
          :const-id="constructionRecord.fulcrum_id"
        />
        <construction-record-editable
          :project-id="project.fulcrum_id"
          :contractor-list-record="project.contractor_list_record"
          :const-id="constructionRecord.fulcrum_id"
          :contingency="project.contingency"
          :datatable-key="datatableKey"
        />
        <v-divider></v-divider>
        <v-overlay :value="isLoadEstimateItemsDialogOpen">
          <!-- load estimate items for current construction record if they haven't been loaded -->
          <load-record-estimate-items
            v-if="projectHasLoaded"
            :is-load-estimate-items-dialog-open.sync="
              isLoadEstimateItemsDialogOpen
            "
            :project-id="projectId"
            :const-id="constId"
            :short-list-id="shortListId"
            @create-estimate-item-list="processFormData"
            @close-dialog="closeLoadRecordEstimateItemsDialog"
            @close-dialog-redirect="
              closeLoadRecordEstimateItemsDialogAndRedirect
            "
          />
        </v-overlay>
      </v-card>
    </v-col>

    <!-- Right Column: Invoice Action -->
    <v-col cols="12" md="3">
      <v-card class="mb-6">
        <v-card-text>
          <v-btn
            class="mb-3"
            color="primary"
            block
            outlined
            @click="handleFormSubmit"
          >
            <v-icon class="me-2" left>
              {{ icons.mdiContentSaveOutline }}
            </v-icon>
            Save Items
          </v-btn>

          <v-btn
            class="mb-3"
            color="primary"
            block
            outlined
            :to="{
              name: 'apps-construction-record-preview',
              params: {
                project: projectId,
                record: constId
              }
            }"
          >
            Preview
          </v-btn>

          <v-btn
            color="success"
            class="mb-3"
            block
            @click="handleMarkAsComplete"
          >
            <v-icon class="me-2" left>
              {{ icons.mdiCheckDecagramOutline }}
            </v-icon>
            <span>Mark as Complete</span>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
